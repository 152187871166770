import Vue from 'vue'
import VueRouter from 'vue-router/dist/vue-router.esm'

//const DEBUG_ROUTER = true

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  routes: [
  ]
})

export default router
